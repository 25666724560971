import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import axios from 'axios';
import CONSTANTES from '../Constantes.js';

// Layouts
const ContentMasterLayout = () =>
    import ('@/Layout/Master/ContentLayout');
const ContentAdminLayout = () =>
    import ('@/Layout/Administrador/ContentLayout');

//middlewates
Vue.use(Router);

function checkAccessProfile(to, from, next) {
    if (!!to.matched[1].meta.isPerfil.find((x) => x == store.state.user.uidProfile)) {
        next();
    } else {
        next({
            name: 'Panel de Control',
        });
        console.log('Acceso denegado solo el administrador puede ingresar');
    }
}

function checkAccessModule(to, from, next) {
    if (store.state.modules.find((x) => x.identificador == to.matched[1].meta.module.name)) {
        next();
    } else {
        next({
            name: 'Panel de Control',
        });
        console.log('no tiens permiso para estar aqui');
    }
}

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
    if (!to.fullPath.includes('login') && to.name != '404') {
        if (localStorage.getItem('token')) {
            axios({
                    method: 'post',
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                    url: CONSTANTES.URL_RUTA_SERVICIOS + 'auth/valida-token',
                })
                .then((response) => {
                    store.commit('SET_USER_DATA', response.data.user);
                    store.commit('SET_MODULES_DATA', response.data.modules);
                    if (to.meta.isTipo) {
                        if (response.data.user.uidType == to.meta.isTipo) {
                            next();
                        } else {
                            router.push({ name: 'Pagina404' });
                        }
                    } else {
                        next();
                    }
                })
                .catch(function(error) {
                    localStorage.clear();
                    router.push({ name: 'Pagina404' });
                });
        } else {
            localStorage.clear();
            router.push({ name: 'Login' });
        }
    } else {
        next();
    }
});

function configRoutes() {
    return [{
            path: '/',
            redirect: '/master/panel-principal',
            component: {
                render(c) {
                    return c('router-view');
                },
            },
            children: [{
                    path: '/',
                    name: 'router page',
                    component: () =>
                        import ('@/views/Paginas/RouterPage.vue'),
                },
                {
                    path: 'master',
                    redirect: '/master/panel-principal',
                    meta: {
                        isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                        label: 'Inicio',
                    },
                    component: ContentMasterLayout,
                    children: [{
                            path: 'panel-principal',
                            name: 'Panel Principal master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Panel Principal',
                            },
                            component: () =>
                                import ('@/views/Master/PanelPrincipal.vue'),
                        },
                        {
                            path: 'perfil',
                            name: 'Perfil master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Mi Perfil',
                            },
                            component: () =>
                                import ('@/views/Paginas/PerfilMaster.vue'),
                        },
                        {
                            path: 'posibles-clientes',
                            name: 'Posibles Clientes master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Posibles clientes',
                            },
                            component: () =>
                                import ('@/views/Master/PosiblesClientes.vue'),
                        },

                        {
                            path: 'clientes',
                            name: 'Clientes master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Clientes',
                            },
                            component: () =>
                                import ('@/views/Master/Clientes.vue'),
                        },
                        {
                            path: 'gestion-cliente/:id',
                            name: 'Gestion del Cliente master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Gestión del cliente',
                            },
                            component: () =>
                                import ('@/views/Master/GestionCliente.vue'),
                        },
                        {
                            path: 'documentos-comerciales',
                            name: 'Documentos comerciales master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Documentos comerciales',
                            },
                            component: () =>
                                import ('@/views/Master/DocumentosComerciales.vue'),
                        },
                        {
                            path: 'archivos/:id/:nombre',
                            name: 'archivos master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Archivos de la carpeta',
                            },
                            component: () =>
                                import ('@/views/Master/VerDocumentos.vue'),
                        },
                        {
                            path: 'colaboradores',
                            name: 'Colaboradores master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Colaboradores',
                            },
                            component: () =>
                                import ('@/views/Master/Colaboradores.vue'),
                        },
                        {
                            path: 'ofertas',
                            name: 'Ofertas master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Ofertas',
                            },
                            component: () =>
                                import ('@/views/Master/Ofertas.vue'),
                        },
                        {
                            path: 'gestion-oferta/:id?',
                            name: 'Gestión de oferta master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Gestión oferta',
                            },
                            component: () =>
                                import ('@/views/Master/GestionOferta.vue'),
                        },
                        {
                            path: 'ventas',
                            name: 'Ventas master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Ventas',
                            },
                            component: () =>
                                import ('@/views/Master/Ventas.vue'),
                        },
                        {
                            path: 'gestion-venta/:id?',
                            name: 'Gestión de venta master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Gestión venta',
                            },
                            component: () =>
                                import ('@/views/Master/GestionVenta.vue'),
                        },
                        {
                            path: 'servicios',
                            name: 'Servicios master',
                            meta: {
                                isTipo: '4b450506-0380-4a54-b05a-ba623fe749a4',
                                label: 'Servicios',
                            },
                            component: () =>
                                import ('@/views/Master/Servicios.vue'),
                        },
                    ],
                },
            ],
        },
        {
            path: '',
            redirect: '/panel-de-control',
            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Inicio' },
            component: ContentAdminLayout,
            children: [{
                    path: 'crm',
                    redirect: '/crm/clientes',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        isPerfil: ['45f0ec93-8762-4071-b6db-dbce75fddb55'],
                        label: 'Comercial',
                    },
                    name: 'Comercial',
                    beforeEnter: checkAccessProfile,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                            path: '/crm/clientes',
                            name: 'Clientes cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Clientes',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/Clientes.vue'),
                        },
                        {
                            path: '/crm/posibles-clientes',
                            name: 'Posibles clientes cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Posibles clientes',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/PosiblesClientes.vue'),
                        },
                        {
                            path: '/crm/gestion-cliente/:id',
                            name: 'Gestion cliente cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión cliente',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/GestionCliente.vue'),
                        },
                        {
                            path: '/crm/cotizaciones-certificacion',
                            name: 'Cotizaciones certificacion',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Cotizaciones de cerfiticación',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/Cotizaciones.vue'),
                        },
                        {
                            path: '/crm/gestion-cotizacion/:id?/:idSubCliente?',
                            name: 'Gestión de cotización cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de cotización',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/GestionCotizacion.vue'),
                        },
                        {
                            path: '/crm/vendedores',
                            name: 'Vendedores cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Vendedores',
                            },
                            component: () =>
                                import ('@/views/Cliente/CRM/Vendedores.vue'),
                        },
                    ],
                },
                {
                    path: 'rrhh',
                    redirect: '/rrhh/puestos-trabajo',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        isPerfil: ['45f0ec93-8762-4071-b6db-dbce75fddb55'],
                        label: 'Recursos Humanos',
                    },
                    name: 'Recursos Humanos',
                    beforeEnter: checkAccessProfile,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                            path: '/rrhh/personal',
                            name: 'Personal cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Personal',
                                module: { name: '001-DOC-DEC' },
                            },
                            component: () =>
                                import ('@/views/Cliente/RRHH/Personal.vue'),
                        },
                        {
                            path: '/rrhh/puestos-trabajo',
                            name: 'Puestos cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Puestos de Trabajo',
                                module: { name: '001-DOC-DEC' },
                            },
                            component: () =>
                                import ('@/views/Cliente/RRHH/PuestosTrabajo.vue'),
                        },
                    ],
                },

                {
                    path: 'pack',
                    redirect: '/pack/documentos-stage-1',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        isPerfil: ['45f0ec93-8762-4071-b6db-dbce75fddb55'],
                        label: 'Dcoumentos auditoria',
                        module: { name: '001-AUD' },
                    },
                    name: 'Pack',
                    beforeEnter: checkAccessProfile,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                            path: '/pack/documentos-stage-1',
                            name: 'Documentos Stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Documentos STAGE 1',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/DocumentosS1.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-1/gestion-plan-auditoria/:id?',
                            name: 'Gestión plan de auditoría stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión plan de auditoría',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionPlanAuditoriaS1.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-1/gestion-asignacion-equipo-auditor/:id?',
                            name: 'Gestion asignacion equipo auditor stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión asignación de equipo auditor',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionAsignacionEquipoAuditorS1.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-1/gestion-estudio-conflictos/:id?',
                            name: 'Gestion estudios conflictos intereses stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión estudio conflictos de intereses solicitantes',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionEstudioConflictosS1.vue'),
                        },
                        {
                            path: '/pack/ingreso-de-documentos-stage-1',
                            name: 'Ingreso de documentos Stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Ingreso de documentos STAGE 1',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS1/IngresoDocumentosS1.vue'),
                        },
                        {
                            path: '/pack/ingreso-de-documentos-stage-1/gestion-report/:id?',
                            name: 'Gestión report stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión report' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS1/GestionReportS1.vue'),
                        },
                        
                        {
                            path: '/pack/documentos-stage-2',
                            name: 'Documentos Stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Documentos STAGE 2',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/DocumentosS2.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-2/gestion-estudio-conflictos/:id?',
                            name: 'Gestion estudios conflictos intereses stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión estudio conflictos de intereses solicitantes',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionEstudioConflictosS2.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-2/gestion-plan-auditoria/:id?',
                            name: 'Gestión plan de auditoría stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión plan de auditoría',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionPlanAuditoriaS2.vue'),
                        },
                        {
                            path: '/pack/documentos-stage-2/gestion-asignacion-equipo-auditor/:id?',
                            name: 'Gestion asignacion equipo auditor stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión asignación de equipo auditor',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionAsignacionEquipoAuditorS2.vue'),
                        },
                        {
                            path: '/pack/ingreso-de-documentos-stage-2',
                            name: 'Ingreso de documentos Stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Ingreso de documentos STAGE 2',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/IngresoDocumentosS2.vue'),
                        }, {
                            path: '/pack/ingreso-de-documentos-stage-2/gestion-report/:id?',
                            name: 'Gestión report stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión report' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionReportS2.vue'),
                        }, {
                            path: '/pack/ingreso-de-documentos-stage-2/gestion-audit-team-approval/:id?',
                            name: 'Gestión audit team approval stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión audit team approval' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionAuditTeamApprovalS2.vue'),
                        },
                       
                        {
                            path: '/pack/documentos-stage-2/gestion-check-list/:id?',
                            name: 'Gestión checkList stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión CheckList stage 2',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionCheckListS2.vue'),
                        }, {
                            path: '/pack/documentos-stage-2/gestion-tecnical-report/:id?',
                            name: 'Gestión Tecnical Report stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Informe de experto técnico',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionTecnicalReportS2.vue'),
                        }, {
                            path: '/pack/documentos-stage-2/gestion-cliente-feedback/:id?',
                            name: 'Gestión Cliente feedback stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Feedback del cliente',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionClientFeedbackS2.vue'),
                        }, {
                            path: '/pack/documentos-stage-2/gestion-reporte-nc/:id?',
                            name: 'Gestión Reporte nc stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Reporte de no conformidad',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionReporteNCS2.vue'),
                        },{
                            path: '/pack/check-list-inicial',
                            name: 'Check List de Informacion',
                            meta: { 
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', 
                                label: 'Check List de Informacion', 
                            },
                            component: () => import('@/views/Cliente/PACK/CheckListInicial/CheckListDeInformacion.vue'),
                        },{
                            path: '/pack/check-list-inicial/gestion-cl-inicial/:id?',
                            name: 'Evaluacion de Proveedores',
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Check List Inicial' },
                            component: () => import ('@/views/Cliente/PACK/CheckListInicial/EvaluacionDeProveedores.vue'),
                        }
                    ],
                },
                //CREANDO LA FUNCIÓN PLANEACIÓN - JEANCARLO
                {
                    path: 'planeacion',
                    redirect: '/planeacion/asignacion-cliente',
                    meta: { 
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        // isPerfil: ['45f0ec93-8762-4071-b6db-dbce75fddb55'],
                        label: 'Planeación',
                        module: { name: '001-AUD' },
                    },
                    name: 'Planeación',
                    beforeEnter: checkAccessModule,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                            path: '/planeacion/asignacion-auditoria',
                            name: 'Asignacion cliente',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { 
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', 
                                label: 'Asignación de auditoria',
                            },
                            component: () =>
                                import ('@/views/Cliente/Planeación/AsignacionAuditoria.vue'),
                        },
                        { path: '/planeacion/asignacion-auditoria/gestion-asignacion-auditoria/:id?',
                        name: 'Nueva Auditoria',
                        meta: {
                            isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                            label: 'Gestion asignación auditoria',
                        },
                        component: () =>
                            import ('@/views/Cliente/Planeación/GestionAsignacionAuditoria.vue'),

                        }
                       
                    ],
                },

                {
                    path: 'auditoria',
                    redirect: '/auditoria/ejecucion-auditoria',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Auditoría',
                        module: { name: '001-AUD' },
                    },
                    name: 'Auditoría',
                    beforeEnter: checkAccessModule,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                        path: '/auditoria/ejecucion-auditoria',
                        name: 'ejecutar auditoria',
                        // beforeEnter: checkPermissionsSubModule,
                        meta: {
                            isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                            label: 'Ejecucion de auditoria',
                          
                        },
                        component: () =>
                            import ('@/views/Cliente/Auditorias/EjecucionAuditoria.vue'),
                    }, 
                    // {
                    //     path: '/auditorias/auditoria',
                    //     name: 'Ejecutar Auditoria',
                    //     // beforeEnter: checkPermissionsSubModule,
                    //     meta: {
                    //         isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                    //         label: 'Ejecución de la Auditoria',
                    //     },
                    //     component: () =>
                    //         import ('@/views/Cliente/Auditorias/Auditoria.vue'),
                    // },
                    {
                        path: '/auditoria/ejecucion-auditoria/contratos-documentos/:idAuditoria/:id?',
                        name: 'Contratos y Documentos',
                        // beforeEnter: checkPermissionsSubModule,
                        meta: {
                            isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                            label: 'Contratos y Documentos',
                        },
                        component: () =>
                            import ('@/views/Cliente/Auditorias/ContratosDocumentos.vue'),
                    },
                    {
                        path: '/auditoria/ejecucion-auditoria/formatos-inciales/:eu/:idFormato',
                        name: 'Formato lista de verificacion inicial',
                        // beforeEnter: checkPermissionsSubModule,
                        meta: {
                            isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                            label: 'Formato lista de verificacion inicial',
                        },
                        component: () =>
                            import ('@/views/Cliente/Auditorias/FormatoListaVerificacionInicial.vue'),
                    },
                     {
                            path: '/auditoria/ejecucion-auditoria/gestion-plan-auditoria-s1/:eu/:id',
                            name: 'eu Gestión plan de auditoría stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión plan de auditoría',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionPlanAuditoriaS1.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-asignacion-equipo-auditor-s1/:eu/:id',
                            name: 'eu Gestion asignacion equipo auditor stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestion asignacion equipo auditor',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionAsignacionEquipoAuditorS1.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-estudio-conflictos-s1/:eu/:id',
                            name: 'eu Gestion estudios conflictos intereses stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión estudio conflictos de intereses solicitantes',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS1/GestionEstudioConflictosS1.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-report-s1/:eu/:id',
                            name: 'eu Gestión report stage 1',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión report' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS1/GestionReportS1.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-plan-auditoria-s2/:eu/:id',
                            name: 'au Gestión plan de auditoría stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión plan de auditoría',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionPlanAuditoriaS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-asignacion-equipo-auditor-s2/:eu/:id',
                            name: 'au Gestion asignacion equipo auditor stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión asignación de equipo auditor',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionAsignacionEquipoAuditorS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-estudio-conflictos-s2/:eu/:id',
                            name: 'au Gestion estudios conflictos intereses stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión estudio conflictos de intereses solicitantes',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/DocumentosS2/GestionEstudioConflictosS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-check-list/:eu/:id',
                            name: 'au Gestión checkList stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión CheckList stage 2',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionCheckListS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-report-s2/:eu/:id',
                            name: 'au Gestión report stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión report' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionReportS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-tecnical-report/:eu/:id',
                            name: 'au Gestión Tecnical Report stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Informe de experto técnico',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionTecnicalReportS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-cliente-feedback/:eu/:id',
                            name: 'au Gestión Cliente feedback stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Feedback del cliente',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionClientFeedbackS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-reporte-nc/:eu/:id',
                            name: 'au Gestión Reporte nc stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Gestión de Reporte de no conformidad',
                            },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionReporteNCS2.vue'),
                        },
                        {
                            path: '/auditoria/ejecucion-auditoria/gestion-audit-team-approval/:eu/:id',
                            name: 'au Gestión audit team approval stage 2',
                            // beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef', label: 'Gestión audit team approval' },
                            component: () =>
                                import ('@/views/Cliente/PACK/IngresoDocumentosS2/GestionAuditTeamApprovalS2.vue'),
                        },
                    // {
                    //     path: '/auditorias/auditoria/carga',
                    //     name: 'Carga de Documentos',
                    //     // beforeEnter: checkPermissionsSubModule,
                    //     meta: {
                    //         isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                    //         label: 'Carga de Documentos',
                    //     },
                    //     component: () =>
                    //         import ('@/views/Cliente/Auditorias/CargaDocumentos.vue'),
                    // },
                    // {
                    //     path: '/auditorias/auditoria/auditoria',
                    //     name: 'Documentos Auditoria',
                    //     // beforeEnter: checkPermissionsSubModule,
                    //     meta: {
                    //         isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                    //         label: 'Documentos Auditoria',
                    //     },
                    //     component: () =>
                    //         import ('@/views/Cliente/Auditorias/DocumentosAuditoria.vue'),
                    // },
                 ],
                },
                {
                    path: 'homologacion',
                    redirect: '/homologacion/index',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Homologación',
                        module: { name: '002-HOM' },
                    },
                    name: 'Homologación',
                    beforeEnter: checkAccessModule,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                            path: '/homologacion/index',
                            name: 'homologacion index cliente',
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Homologaciones index',
                            },
                            component: () =>
                                import ('@/views/Cliente/Homologaciones/index.vue'),
                        },
                        {
                            path: '/homologacion/index2',
                            name: 'homologacion index2 cliente',
                            meta: {
                                isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                                label: 'Homologaciones index2',
                            },
                            component: () =>
                                import ('@/views/Cliente/Homologaciones/index2.vue'),
                        },
                    ],
                },

                {
                    path: 'inspecciones',
                    redirect: '/inspecciones/index',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Inspecciones',
                        module: { name: '003-INS' },
                    },
                    name: 'Inspecciones',
                    beforeEnter: checkAccessModule,
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [{
                        path: '/inspecciones/index',
                        name: 'inspecciones index cliente',
                        // beforeEnter: checkPermissionsSubModule,
                        meta: {
                            isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                            label: 'inspecciones index',
                        },
                        component: () =>
                            import ('@/views/Cliente/Inspecciones/index.vue'),
                    }, ],
                },

                {
                    path: 'panel-de-control',
                    name: 'Panel de Control',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Panel de control',
                    },
                    component: () =>
                        import ('@/views/Cliente/PanelControl.vue'),
                },
                {
                    path: 'perfil',
                    name: 'Perfil Coordinador',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Perfil',
                    },
                    component: () =>
                        import ('@/views/Paginas/Perfil.vue'),
                },

                // {
                //     path: 'clientes',
                //     name: 'Clientes cliente',
                //     meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Clientes", },
                //     component: () =>
                //         import ('@/views/Cliente/Clientes.vue'),
                // },
                {
                    path: 'ofertas',
                    name: 'Ofertas',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Ofertas',
                    },
                    component: () =>
                        import ('@/views/Cliente/Ofertas.vue'),
                },
                {
                    path: 'ventas',
                    name: 'Ventas',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Ventas',
                    },
                    component: () =>
                        import ('@/views/Cliente/Ventas.vue'),
                },
                {
                    path: 'vendedores',
                    name: 'Vendedores',
                    meta: {
                        isTipo: 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef',
                        label: 'Vendedores',
                    },
                    component: () =>
                        import ('@/views/Cliente/Vendedores.vue'),
                },
                // {
                //     path: 'documentos-comerciales',
                //     name: 'Documentos comerciales',
                //     meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Documentos comerciales", },
                //     component: () =>
                //         import ('@/views/Cliente/DocumentosComerciales.vue'),
                // }
            ],
        },

        /* {
            path: '/',
            component: ContentCoordinadorCuestionariosLayout,
            children: [{
                path: 'cuestionarios',
                redirect: '/cuestionarios/evaluacion-del-proveedor',
                meta: { label: "Cuestionarios" },
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'evaluacion-del-proveedor',
                        name: 'Vista Evaluacion del proveedor',
                        meta: { label: "Evaluación del proveedor", subTitle: "CUESTIONARIO ONLINE DE EVALUACIÓN DE PROVEEDORES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoEvaluacionProveedor.vue'),
                    }, {
                        path: 'evaluacion-del-proveedor/:token/:id',
                        name: 'Evaluacion del proveedor',
                        meta: { label: "Evaluación del proveedor", subTitle: "CUESTIONARIO ONLINE DE EVALUACIÓN DE PROVEEDORES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoEvaluacionProveedor.vue'),
                    },
                    {
                        path: 'satisfaccion-del-cliente',
                        name: 'Vista Satisfaccion del cliente',
                        meta: { label: "Satisfacción del cliente", subTitle: "CUESTIONARIO ONLINE DE SATISFACCIÓN DE CLIENTES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoSatisfaccionCliente.vue'),
                    }, {
                        path: 'satisfaccion-del-cliente/:token/:id',
                        name: 'Satisfaccion del cliente',
                        meta: { label: "Satisfacción del cliente", subTitle: "CUESTIONARIO ONLINE DE SATISFACCIÓN DE CLIENTES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoSatisfaccionCliente.vue'),
                    }
                ]
            }]
        }, */
        {
            path: '/',

            component: {
                render(c) {
                    return c('router-view');
                },
            },
            children: [{
                    path: '404',
                    name: 'Pagina404',
                    component: () =>
                        import ('@/views/Paginas/404.vue'),
                },
                {
                    path: '500',
                    name: 'Pagina500',
                    component: () =>
                        import ('@/views/Paginas/500.vue'),
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: () =>
                        import ( /* webpackChunkName: "Login" */ '@/views/Paginas/Login.vue'),
                },
                {
                    path: ':rsCliente/login',
                    name: 'Login Cliente',
                    component: () =>
                        import ( /* webpackChunkName: "Login Cliente" */ '@/views/Paginas/LoginCliente.vue'),
                },
                {
                    path: 'recuperar',
                    name: 'Recuperar',
                    component: () =>
                        import ('@/views/Paginas/Recuperar.vue'),
                },
                {
                    path: '*',
                    name: '404',
                    component: () =>
                        import ('@/views/Paginas/404.vue'),
                },
            ],
        },
    ];
}

export default router;