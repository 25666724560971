import {
    cilBell,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilPeople,
    cilSpeedometer,
    cilFork,
    cilBriefcase,
    cilCart,
    cilMoney,
    cilGroup,
    cilFolder,
    cilWarning,
    cilCog,
    cilFingerprint
} from '@coreui/icons'

import { logo } from './logo'

export const iconsSet = Object.assign({}, { logo }, {
    cilBell,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilPeople,
    cilSpeedometer,
    cilFork,
    cilBriefcase,
    cilCart,
    cilMoney,
    cilGroup,
    cilFolder,
    cilWarning,
    cilCog,
    cilFingerprint
})